import { ref, watch, Ref } from 'vue'
import { useRequest } from 'vue-request'
import {
  BaseResponse,
  Competition,
  BasePagination,
  CompetitionQueryDto,
  ICompetition,
  SubCompetitionQueryParamsDto,
  SubCompetition,
  ITeam,
  TeamInSubCompetitionQueryParamsDto,
  QueryParams,
  IImage,
  SubCompetitionImage,
  IVideo,
  CompetitionType,
  IVersusMatch
} from '@/_modules/types'
import { competitionService } from '@/_services'
import { format, getYear } from 'date-fns'
import { cs, id } from 'date-fns/locale'
interface ReturnType {
  repositories: Ref<ICompetition[]>;
  loading: Ref<boolean>;
  getCompetitionRepositories: (q: CompetitionQueryDto) => Promise<ICompetition[] | null>;
  getCompetitions: (type: CompetitionType, q: QueryParams) => Promise<BasePagination<ICompetition[]> | null>;
  fetchingCompetitions: Ref<boolean>,
  getCompetition: (id: number) => Promise<ICompetition | null>;
  getSubCompetitions: (id: number, query: SubCompetitionQueryParamsDto) => Promise<BasePagination<ICompetition[]> | null>;
  fetchingSubCompetitions: Ref<boolean>;
  getSubCompetition: (competitionId: number, subCompetitionId: number) => Promise<ICompetition | null>;
  fetchingSubCompetition: Ref<boolean>;
  getTeams: (competitionId: number, subCompetitionId: number, q: TeamInSubCompetitionQueryParamsDto) => Promise<BasePagination<ITeam[]> | null>;
  fetchingTeamsInSubCompetition: Ref<boolean>;
  getImages: (competitionId: number, subCompetitionId: number, q: QueryParams) => Promise<BasePagination<IImage[]> | null>;
  fetchingImages: Ref<boolean>;
  getVideos: (competitionId: number, subCompetitionId: number, q: QueryParams) => Promise<BasePagination<IVideo[]> | null>;
  fetchingVideos: Ref<boolean>;
  getVersusMatch: (competitionId: number, subCompetitionId: number, competitionSectionId: number, versusMatchId: number) => Promise<any | null>;
  getKnoctoutFinal: (competitionId: number, subCompetitionId: number) => Promise<any | null>;
  getKnoctoutSemiFinal: (competitionId: number, subCompetitionId: number) => Promise<any | null>;

  getKnoctoutThirdPlaceMatch: (competitionId: number, subCompetitionId: number) => Promise<any | null>;
  getKnoctoutThirdRound: (competitionId: number, subCompetitionId: number) => Promise<any | null>;
  getKnoctoutSecondRound: (competitionId: number, subCompetitionId: number) => Promise<any | null>;
    getKnoctoutLoserSemi: (competitionId: number, subCompetitionId: number) => Promise<any | null>;
    getKnoctoutQuarterFinal: (competitionId: number, subCompetitionId: number) => Promise<any | null>;

}
export default function useCompetitionRepositories(q?: Ref<CompetitionQueryDto>): ReturnType {
  const repositories = ref<ICompetition[]>([])
  const cb = (ele: Competition) => {
    if (!ele) return {} as ICompetition
    const {
      id,
      setting_competition_informations: info,
      setting_competition_media_profile_icon: logo,
      global_config_standard_types,
      setting_competition_level,
      setting_competition_social_contacts: contact,
      count_sub_competition,
    } = ele
    const dataRespone: any = {
      id: id ?? 0,
      name: {
        en: info?.setting_competition_information_name_en ?? '-',
        th: info?.setting_competition_information_name_th ?? '-'
      },
      logo: logo ?? info.setting_competition_media_profile_url,
      host: {
        th: info?.setting_competition_information_organizer_th ?? '-',
        en: info?.setting_competition_information_organizer_en ?? '-',
      },
      info: {
        code: info?.setting_competition_information_code ?? '-',
        level: setting_competition_level ?? global_config_standard_types?.global_config_standard_type_name_th ?? '-',
        type: setting_competition_level ?? '-',
        teamType: {
          th: global_config_standard_types?.global_config_standard_type_name_th ?? '-',
          en: global_config_standard_types?.global_config_standard_type_name_en ?? '-',
        },
        totalSubCompetition: count_sub_competition ?? 0
      },
      history: {
        th: info?.setting_competition_information_history_th ?? 'ไม่มีข้อมูล',
        en: info?.setting_competition_information_history_en ?? 'No Data',
      },
      contact: {
        email: contact?.setting_competition_social_contact_email || '-',
        phone: contact?.setting_competition_social_contact_phone || '-',
        instagram: contact?.setting_competition_social_contact_instagram || '-',
        line: contact?.setting_competition_social_contact_line || '-',
        facebook: contact?.setting_competition_social_contact_facebook || '-',
        twitter: contact?.setting_competition_social_contact_twitter || '-',
        youtube: contact?.setting_competition_social_contact_youtube || '-',
        website: contact?.setting_competition_social_contact_website || '-'
      },
    }
    // console.log('dataRespone', dataRespone);

    return dataRespone
  }
  const formatResult = (result: BaseResponse<BasePagination<Competition[]>>) => {
    const { competition } = result.data ?? result
    if (!competition?.data) return []
    return competition.data?.map(cb)
  }

  const { run: getCompetitionRepositories, loading } = useRequest(
    competitionService.list,
    {
      manual: true,
      formatResult,
      onSuccess: formattedData => {
        repositories.value = formattedData
      }
    }
  )

  const { run: getCompetitions, loading: fetchingCompetitions } = useRequest(
    competitionService.getByLevel,
    {
      manual: true,
      formatResult: ({ data }) => {
        const { data: competitions, ...others } = data.competition
        if (!competitions?.length) {
          return null
        }
        return {
          competition: {
            ...others,
            data: competitions.map(cb)
          }
        } as unknown as BasePagination<ICompetition[]>
      }
    }
  )

  const { run: getCompetition } = useRequest(
    competitionService.getById,
    {
      manual: true,
      formatResult: (result) => {
        const { data } = result
        if (data.competition) {
          return cb(data.competition)
        }
        return null
      }
    }
  )

  const cbSubCompetition = (ele: SubCompetition) => {
    const {
      setting_sub_competition_media_profile_url: logo, id, setting_sub_competition_history_th, setting_sub_competition_history_en,
      setting_sub_competition_name_en: en, setting_sub_competition_name_th: th, setting_sub_competition_hostname, setting_sub_competition_hostname_en,
      setting_sub_competition_year: year, setting_sub_competition_start_at: startDate,
      setting_sub_competition_end_at: endDate,
      setting_sub_competition_media_profile_icon: icon,
      setting_sub_competition_social_contacts: contacts,
      setting_sub_competition_category: categoryTh,
      setting_sub_competition_category_en: categoryEn,
      setting_sub_competition_awards: award
    } = ele
    return {
      logo: logo ?? icon,
      id,
      name: {
        en,
        th
      },
      history: {
        th: setting_sub_competition_history_th ?? 'ไม่มีข้อมูล',
        en: setting_sub_competition_history_en ?? 'No Data',
      },
      host: {
        th: setting_sub_competition_hostname,
        en: setting_sub_competition_hostname_en
      },
      year,
      startDate: format(new Date(startDate), 'dd/MM/yyyy'),
      endDate: format(new Date(endDate), 'dd/MM/yyyy'),
      contacts,
      type: {
        th: categoryTh,
        en: categoryEn
      },
      winner: {
        th: award?.gold?.team_name_th ?? '-',
        en: award?.gold?.team_name_en ?? '-',
      },
      winnerLogo: award?.gold?.team_profile_url

    } as ICompetition
  }

  const { run: getSubCompetitions, loading: fetchingSubCompetitions } = useRequest(
    competitionService.getSubCompetitions,
    {
      manual: true,
      formatResult: ({ data }) => {
        const { sub_competition } = data
        if (!sub_competition.data?.length) {
          return sub_competition as unknown as BasePagination<ICompetition[]>
        }
        const { data: subData, ...others } = sub_competition;
        const formated = subData?.map(cbSubCompetition)

        return {
          sub_competition: {
            ...others,
            data: formated
          }
        } as unknown as BasePagination<ICompetition[]>
      }
    }
  )
  const { run: getSubCompetition, loading: fetchingSubCompetition } = useRequest(
    competitionService.getSubCompetition,
    {
      manual: true,
      formatResult: ({ data }) => {
        const { sub_competition } = data
        return cbSubCompetition(sub_competition)
      }
    }
  )

  const { run: getTeams, loading: fetchingTeamsInSubCompetition } = useRequest(
    competitionService.getTeams,
    {
      manual: true,
      formatResult: ({ data }) => {
        const { sub_competition } = data
        const { data: subData, ...others } = sub_competition;
        return {
          sub_competition: {
            ...others,
            data: subData.map(ele => {
              const {
                id, setting_team_information_name_en: en, setting_team_information_name_th: th,
                setting_team_media_profile_url: logo
              } = ele
              return {
                id,
                name: {
                  en, th
                },
                logo
              } as ITeam
            })
          }
        } as unknown as BasePagination<ITeam[]>
      }
    }
  )

  const formatMedia = (result: BasePagination<SubCompetitionImage[]>) => {
    const { medias } = result
    const { data, ...others } = medias
    if (!data?.length) {
      return null
    }
    const tmp = data.map(ele => {
      return {
        src: ele.setting_sub_competition_media_url,
        alt: ele.setting_sub_competition_media_name
      } as IImage
    })
    return {
      medias: {
        ...others, data: [...tmp]
      }
    } as unknown as BasePagination<IImage[]>
  }

  const { run: getImages, loading: fetchingImages } = useRequest(
    competitionService.getImages,
    {
      manual: true,
      formatResult: ({ data: result }) => {
        return formatMedia(result)
      }
    }
  )

  const { run: getVideos, loading: fetchingVideos } = useRequest(
    competitionService.getVideos,
    {
      manual: true,
      formatResult: ({ data: result }) => {
        const { medias } = result
        const { data, ...others } = medias
        if (!data?.length) {
          return null
        }
        const tmp = data.map(ele => {
          return {
            src: ele.setting_sub_competition_media_url,
            alt: ele.setting_sub_competition_media_name
          } as IVideo
        })
        return {
          medias: {
            ...others, data: [...tmp]
          }
        } as unknown as BasePagination<IVideo[]>
      }
    }
  )

  const formatVersusMatch = (ele: any) => {
    const { versus_match } = ele
    if (versus_match?.lenght) {
      return null
    }
    const splitDate = versus_match[0]?.versus_match_date.split('-');
    const date = new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2])

    const resultEn = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    const resultTh = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    const timeStart = versus_match[0]?.versus_match_start_at.split(':')
    const timeEnd = versus_match[0]?.versus_match_end_at.split(':')

    const objData = {
      banner: {
        team1: {
          name: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_name,
          nameEn: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_name_en,
          image: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_image,
          score: +versus_match[0]?.versus_match_team_detail_one_id?.versus_match_score ?? 0
        },
        team2: {
          name: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_name,
          nameEn: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_name_en,
          image: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_image,
          score: +versus_match[0]?.versus_match_team_detail_two_id?.versus_match_score ?? 0
        },
        logo: versus_match[0]?.setting_sub_competition?.setting_sub_competition_media_profile_url,
        round: {
          th: versus_match[0]?.competition_sections?.competition_section_name_th,
          en: versus_match[0]?.competition_sections?.competition_section_name_en
        },
        date: {
          th: resultTh,
          en: resultEn
        },
        startTime: `${timeStart[0]}.${timeStart[1]}`,
        endTime: `${timeEnd[0]}.${timeEnd[1]}`,
        place: {
          th: versus_match[0]?.setting_stadiums?.setting_stadium_name_th,
          en: versus_match[0]?.setting_stadiums?.setting_stadium_name_en,
        },
        firstReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_one !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_one?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,
          image: versus_match[0]?.versus_match_referee?.versus_match_referee_one?.refereeImage ?? null,
        } : null,
        secondReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_two !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,
          image: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeImage ?? null,
        } : null,
        thirdReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_three !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_three?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,
          image: versus_match[0]?.versus_match_referee?.versus_match_referee_three?.refereeImage ?? null,
        } : null,
        forthReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_four !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_four?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,

          image: versus_match[0]?.versus_match_referee?.versus_match_referee_four?.refereeImage ?? null,
        } : null,
        fifthReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_five !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_five?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,
          image: versus_match[0]?.versus_match_referee?.versus_match_referee_five?.refereeImage ?? null,
        } : null,
        sixthReferee: versus_match[0]?.versus_match_referee?.versus_match_referee_six !== undefined ? {
          name: versus_match[0]?.versus_match_referee?.versus_match_referee_six?.refereeName ?? null,
          nameEn: versus_match[0]?.versus_match_referee?.versus_match_referee_two?.refereeNameEn ?? null,
          image: versus_match[0]?.versus_match_referee?.versus_match_referee_six?.refereeImage ?? null,
        } : null,
      },
      information: {
        round: {
          th: versus_match[0]?.competition_sections?.competition_section_name_th,
          en: versus_match[0]?.competition_sections?.competition_section_name_en
        },
        host: {
          th: versus_match[0]?.setting_sub_competition?.setting_sub_competition_hostname,
          en: versus_match[0]?.setting_sub_competition?.setting_sub_competition_hostname_en
        },
        holder: {
          th: versus_match[0]?.versus_match_stadium_holder?.name_th ?? '-',
          en: versus_match[0]?.versus_match_stadium_holder?.name_en ?? '-',
        },
        visitor: {
          th: versus_match[0]?.versus_match_visitors,
          en: versus_match[0]?.versus_match_visitors,
        },
        team1: {
          image: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_image,
          staff: {
            th: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_detail_staff?.staffName ?? 'ไม่มีข้อมูล',
            en: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_detail_staff?.staffNameEn ?? 'No data',
          },
          mainPostition: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_detail_player?.filter((p: any) => p.main !== false),
          alternatePosition: versus_match[0]?.versus_match_team_detail_one_id?.versus_match_team_detail_player?.filter((p: any) => p.main === false),
        },
        team2: {
          image: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_image,
          staff: {
            th: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_detail_staff?.staffName ?? '-',
            en: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_detail_staff?.staffNameEn ?? '-',
          },
          mainPostition: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_detail_player?.filter((p: any) => p.main !== false),
          alternatePosition: versus_match[0]?.versus_match_team_detail_two_id?.versus_match_team_detail_player?.filter((p: any) => p.main === false),
        }

      }
    }

    return objData
  }

  const formatKnoctOut = (data: any) => {
    if (data.competition_section.section_type === "GROUP") {
      return data
    } else {
      const objData = {
        competitionSectionId: data.competition_section.id,
        versusMatch: data.competition_section.versus_match.map((vs: any) => {
          return {
            versusMatchId: vs.id,
            date: format(new Date(vs.versus_match_date), 'dd/MM/yyyy'),
            teamOne: {
              name: {
                th: vs.versus_match_team_detail_one_id.versus_match_team_name,
                en: vs.versus_match_team_detail_one_id.versus_match_team_name_en,

              },
              image: vs.versus_match_team_detail_one_id.versus_match_team_image,
              score: +vs.versus_match_team_detail_one_id.versus_match_score,
              result: (vs.versus_match_team_detail_one_id.versus_match_score === null || vs.versus_match_team_detail_one_id.versus_match_score === null) ? null : +vs.versus_match_team_detail_one_id.versus_match_score > +vs.versus_match_team_detail_two_id.versus_match_score ? 'w' :
                +vs.versus_match_team_detail_one_id.versus_match_score < +vs.versus_match_team_detail_two_id.versus_match_score ? 'l' : 'a'
            },
            teamTwo: {
              name: {
                th: vs.versus_match_team_detail_two_id.versus_match_team_name,
                en: vs.versus_match_team_detail_two_id.versus_match_team_name_en,
              },
              image: vs.versus_match_team_detail_two_id.versus_match_team_image,
              score: +vs.versus_match_team_detail_two_id.versus_match_score,
              result: (vs.versus_match_team_detail_one_id.versus_match_score === null || vs.versus_match_team_detail_one_id.versus_match_score === null) ? null : +vs.versus_match_team_detail_one_id.versus_match_score < +vs.versus_match_team_detail_two_id.versus_match_score ? 'w' :
                +vs.versus_match_team_detail_one_id.versus_match_score > +vs.versus_match_team_detail_two_id.versus_match_score ? 'l' : 'a'
            },
            matchResult: {
              versus_match_id: vs.id,
              id: vs?.versus_match_team_detail_one_id?.versus_match_team_id,
              vs: vs?.versus_match_team_detail_two_id?.versus_match_team_id,
              result: +vs.versus_match_team_detail_one_id.versus_match_score > +vs.versus_match_team_detail_two_id.versus_match_score ? 'w' :
                +vs.versus_match_team_detail_one_id.versus_match_score < +vs.versus_match_team_detail_two_id.versus_match_score ? 'l' : 'a',
              score: vs.versus_match_team_detail_one_id.versus_match_score + "-" + vs.versus_match_team_detail_two_id.versus_match_score,
              gf: + vs.versus_match_team_detail_one_id?.versus_match_score,
              gs: +vs.versus_match_team_detail_two_id.versus_match_score,
              gd: +vs.versus_match_team_detail_one_id?.versus_match_score - (+vs.versus_match_team_detail_two_id.versus_match_score),
              type: data.competition_section.section_type
            }
          };
        }),
      };
      return objData
    }
  }
  const { run: getKnoctoutFinal } = useRequest(
    competitionService.getKnoctoutFinal,
    {
      manual: true,
      formatResult: ({ data }) => {
        return formatKnoctOut(data)
      }
    }
  )
  const { run: getKnoctoutSemiFinal } = useRequest(
    competitionService.getKnoctoutSemiFinal,
    {
      manual: true,
      formatResult: ({ data }) => {
        return formatKnoctOut(data)
      }
    }
  )

  const { run: getKnoctoutThirdPlaceMatch } = useRequest(
    competitionService.getKnoctoutThirdPlaceMatch,
    {
      manual: true,
      formatResult: ({ data }) => {
        return formatKnoctOut(data)
      }
    }
  )

  const { run: getKnoctoutThirdRound } = useRequest(
    competitionService.getKnoctoutThirdRound,
    {
      manual: true,
      formatResult: ({ data }) => {
        return formatKnoctOut(data)
      }
    }
  )

    const { run: getKnoctoutSecondRound } = useRequest(
        competitionService.getKnoctoutSecondRound,
        {
            manual: true,
            formatResult: ({ data }) => {
                return formatKnoctOut(data)
            }
        }
    )

    const { run: getKnoctoutLoserSemi } = useRequest(
        competitionService.getKnoctoutLoserSemi,
        {
            manual: true,
            formatResult: ({ data }) => {
                return formatKnoctOut(data)
            }
        }
    )

    const { run: getKnoctoutQuarterFinal } = useRequest(
        competitionService.getKnoctoutQuarterFinal,
        {
            manual: true,
            formatResult: ({ data }) => {
                return formatKnoctOut(data)
            }
        }
    )
  const { run: getVersusMatch } = useRequest(
    competitionService.getVersusMatch,
    {
      manual: true,
      formatResult: ({ data: result }) => {
        return formatVersusMatch(result)
      }
    }
  )
  watch(() => q?.value, () => {
    if (q?.value) {
      getCompetitionRepositories(q.value)
    }
  }, { immediate: true })

  return {
    repositories,
    loading,
    getCompetitionRepositories,
    getCompetitions,
    fetchingCompetitions,
    getCompetition,
    getSubCompetitions,
    fetchingSubCompetitions,
    getSubCompetition,
    fetchingSubCompetition,
    getTeams,
    fetchingTeamsInSubCompetition,
    getImages,
    fetchingImages,
    getVideos,
    fetchingVideos,
    getVersusMatch,
    getKnoctoutFinal,
    getKnoctoutSemiFinal,
    getKnoctoutThirdPlaceMatch,
    getKnoctoutThirdRound,
      getKnoctoutSecondRound,
      getKnoctoutQuarterFinal,
      getKnoctoutLoserSemi
  }
}
